@import "../../variables.scss";

.contact-container {
  width: 100%;
  height: 100vh;
  color: $primary-dark;
  display: flex;
  justify-content: center;
  padding-top: 120px;
}
