@import "../../variables.scss";

.project-card-container {
  width: 400px;
  padding: 20px 10px;
  border-radius: 5px;
  box-shadow: 0px 1px 2px 0px rgba(66, 68, 90, 1);
  transition: 0.5s ease-out;
  cursor: pointer;
  & * {
    padding: 10px 0;
  }

  p {
    font-weight: bold;
  }

  &:hover {
    transform: scale(1.05);
  }
}

@media (max-width: 400px) {
  .project-card-container {
    width: 90vw;
  }
}
