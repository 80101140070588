@import "../../variables.scss";

.home-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100svh;

  .particles {
    position: absolute;
    width: 100%;
    height: 100svh;
    overflow: hidden;
  }
  .home-desc {
    display: flex;
    flex-direction: column;

    z-index: 1;
    color: white;
    font-size: 25px;
    letter-spacing: 5px;
    .name-span {
      color: $primary-light;
    }

    button {
      width: 40%;
      margin-top: 30px;
      border: 3px solid $primary-light;
      padding: 10px;
      align-self: center;
      background-color: transparent;
      color: white;
      font-size: 15px;
      text-transform: uppercase;
      transition: 0.5s;
      font-family: "Montserrat";
      font-weight: bold;
      letter-spacing: 2px;
      border-radius: 3px;
      &:hover {
        background-color: $primary-light;
      }
    }
  }
}

@media (max-width: 900px) {
  .home-container {
    .home-desc {
      font-size: 20px;
    }
  }
}

@media (max-width: 800px) {
  .home-container {
    .home-desc {
      font-size: 15px;
      button {
        font-size: 10px;
      }
    }
  }
}

@media (max-width: 600px) {
  .home-container {
    .home-desc {
      font-size: 15px;
      text-align: center;
    }
  }
}

@media (max-width: 400px) {
  .home-container {
    .home-desc {
      font-size: 12px;
      text-align: center;
      button {
        width: 60%;
        font-size: 10px;
      }
    }
  }
}
