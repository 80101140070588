@import "../../variables.scss";

.about-container {
  width: 100%;
  height: 100vh;
  color: $primary-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;

  p {
    font-size: 20px;
    font-weight: bold;
  }
}
