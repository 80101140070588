@import "../../variables.scss";

.nav-container {
  position: relative;

  button {
    padding: 0 30px;
    border: none;
    background-color: transparent;
    color: white;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 3px;
    transition: 0.5s;
    font-family: "Montserrat";
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
      color: $primary-light;
    }
  }
  .nav-bar-container {
    width: 100%;
    height: 50px;
    background-color: $primary-dark;

    &.bar-floating {
      position: fixed;
      top: 0;
    }

    .buttons-container {
      margin-left: 100px;
      display: flex;
      align-items: center;
      height: 100%;
    }

    .hamburger-container {
      width: 100%;
      height: 100%;
      display: none;
      justify-content: flex-end;
      color: white;
      padding: 0 20px;
    }
  }

  .nav-drawer-container {
    position: absolute;
    width: 100%;
    height: 0;
    display: flex;
    flex-direction: column;
    background-color: $primary-dark;
    transition: 0.5s ease-out;
    overflow: hidden;
    &.drawer-visible {
      height: 180px;
    }
    &.drawer-floating {
      position: fixed;
      top: 50px;
    }
    button {
      padding: 10px 0;
    }
  }
}
@media (max-width: 900px) {
  .nav-container {
    .nav-bar-container {
      .buttons-container {
        margin-left: 20px;
      }
    }
  }
}

@media (max-width: 600px) {
  .nav-container {
    .nav-bar-container {
      .buttons-container {
        display: none;
      }
      .hamburger-container {
        display: flex;
      }
    }
  }
}
