@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");

* {
  margin: 0;
  padding: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

html {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Montserrat";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
