@import "../../variables.scss";

.sample-projects-container {
  width: 100%;
  height: 100vh;
  color: $primary-dark;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;

  h1 {
    margin-bottom: 80px;
  }
}
